import { Link } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const MusicPage = ({ data }) => (
  <Layout useFooter={false}>
    <SEO title="Home" />
    <header>
      <h1>Phil Schanely <small>Arranger and Composer</small></h1>
    </header>
    <section class="music">
      <h2>Musical Works</h2>
      <ul class="music-works">
        <li class="music-work">
          <h3>Behold the Lamb of God</h3>
          <ul>
            <li>
              <Link
                target="_blank"
                href="https://soundcloud.com/phil-schanely/sets/behold-the-lamb-orchestral"
              >
                Orchestral Arrangement (SoundCloud)
              </Link> - My arrangement for choir and orchestra. This album is a set of instrumental "recordings" made with Siblius and NotePerformer.
            </li>
            <li>
              <Link
                target="_blank"
                href="https://www.youtube.com/playlist?list=PLFDF180286B78B279"
              >
                Andrew Peterson's Original album (YouTube playlist)
              </Link> - Andrew Peterson's original album. I based my orchestral arrangement primarily on this album.
            </li>
            <li>
              <Link
                target="_blank"
                href="https://open.spotify.com/album/24WQoKJPICucZjGnvwgohy?si=1vlcvvx_RKm5Z1FF3m7q0A"
              >
                Andrew Peterson's 20th Anniversary Album (Spotify)
              </Link> - This is the most recent album Andrew Peterson has produced for this work. I used portions of this version in my arrangement.
            </li>
          </ul>
        </li>
      </ul>
      <p class="lead">...and more coming soon!</p>
    </section>
  </Layout>
);

export default MusicPage;
